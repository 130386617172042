import React, { useState } from "react";
import EditableField from "../EditableField/EditableField";
import "./PersonalInfo.css";
import iconArrowDown from "../../../../assets/images/icons/icon-arrowdown.svg";
import iconArrowUp from "../../../../assets/images/icons/icon-arrowup.svg";
import { useUserContext } from "../../../../contexts/userContext";

const PersonalInfo = () => {
  const { user } = useUserContext()
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState("demo@databot.es");

  const [isExpanded, setIsExpanded] = useState(true);

  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className="section personal-info">
      <div className="title-section">
        <h2>Información personal</h2>
        <button className="toggle-button" onClick={toggleContent}>
          <img
            src={isExpanded ? iconArrowDown : iconArrowUp}
            alt={isExpanded ? "Collapse" : "Expand"}
          />
        </button>
      </div>
      {isExpanded && (
        <div className="personal-info-content">
          <EditableField
            label="Nombre completo"
            value={name}
            onSave={(newValue) => setName(newValue)}
          />
          <EditableField
            label="Correo electrónico"
            value={email}
            onSave={(newValue) => setName(newValue)}
            />
          <div className="field">
            <label>País o Región</label>
            <select>
              <option>España</option>
            </select>
          </div>
        </div>
      )}
    </section>
  );
};

export default PersonalInfo;