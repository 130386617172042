import React, { useState } from 'react';
import './CiteDocumentsButton.css'; // Asegúrate de crear un archivo CSS para estilos
import { ReactComponent as Iconquote } from '../../../assets/images/icons/icon-quote.svg';
import buildCitations from "../../../services/helpers/buildCitations";
import ButtonTooltip from '../ButtonTooltip/ButtonTooltip';

const CiteDocumentsButton = ({ documents }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleCopy = () => {
    const citations = buildCitations(documents); // Usar la función aislada para construir citas

    navigator.clipboard.writeText(citations)
      .then(() => {
        setTooltipVisible(true); // Mostrar el tooltip al copiar
        setTimeout(() => {
          setTooltipVisible(false); // Ocultar después de 2 segundos
        }, 1500);
      })
      .catch(err => {
        console.error('Error al copiar las citas: ', err);
      });
  };

  return (
    <div className="cite-documents-button" onClick={handleCopy} title="Citar fuentes">
      <Iconquote className="cite-documents-icon" />
      <ButtonTooltip message={"Citas copiadas"} visible={tooltipVisible}/>
    </div>
  );
};

export default CiteDocumentsButton;
