import { useState } from 'react';
import apiRequest from '../services/api/apiRequest';

const useDataSource = () => {
  const [dataSources, setDataSources] = useState([]);
  const [editedDataSourceQuery, setEditedDataSourceQuery] = useState(null)
  const [dataSourceQueries, setDataSourceQueries] = useState([]);
  const [resultsCount, setResultsCount] = useState(0); // Inicializa con 0 resultados
  const [sourceHref, setSourceHref] = useState("#"); // Inicializa el enlace con un valor por defecto

  const [loading, setLoading] = useState(false); // Para manejar el estado de carga
  const [preFetchLoading, setPreFetchLoading] = useState(false); // Estado para controlar la carga
  const [error, setError] = useState(null); // Para manejar los errores
  const [preFetchError, setPreFetchError] = useState(null); // Para manejar los errores

  const fetchDataSources = async () => {
    setLoading(true); // Inicia el estado de carga
    setError(null); // Limpia cualquier error previo

    try {
        setDataSources(() => []);
        const { data, error: apiError } = await apiRequest(
            '/datasources/',
            'GET',
            'include',
            'application/json'
        );

        if (apiError) {
            throw new Error(apiError.message);
        }
        if (data && Array.isArray(data)) {
          setDataSources(data);
        }
        

    } catch (error) {
        console.error('Error fetching dataSource:', error);
        setError(error.message); // Establece el mensaje de error
    } finally {
        setLoading(false); // Termina el estado de carga
    }
  };
  
  const fetchDataSourceQueries = async (dataSourceId) => {
    setLoading(true); // Inicia el estado de carga
    setError(null); // Limpia cualquier error previo

    try {
        setDataSources(() => []);
        const { data, error: apiError } = await apiRequest(
            `/datasources/${dataSourceId}/queries`,
            'GET',
            'include',
            'application/json'
        );

        if (apiError) {
            throw new Error(apiError.message);
        }
        if (data && Array.isArray(data)) {
          setDataSourceQueries(data);
        }
        

    } catch (error) {
        console.error('Error fetching dataSource:', error);
        setError(error.message); // Establece el mensaje de error
    } finally {
        setLoading(false); // Termina el estado de carga
    }
  };

  const fetchDataSourceQuery = async (dataSourceQueryId) => {
    setLoading(true); // Inicia el estado de carga
    setError(null); // Limpia cualquier error previo

    try {
        const { data, error: apiError } = await apiRequest(
            `/datasources/queries/${dataSourceQueryId}`,
            'GET',
            'include',
            'application/json'
        );

        if (apiError) {
            throw new Error(apiError.message);
        }
        if (data) {
          setEditedDataSourceQuery(data);
        } else {
          setEditedDataSourceQuery(null);
        }

    } catch (error) {
        console.error('Error fetching dataSource:', error);
        setError(error.message); // Establece el mensaje de error
    } finally {
        setLoading(false); // Termina el estado de carga
    }
  };

  const preFetchDataSourceQuery = async (body) => {
    setPreFetchError(null); // Limpia cualquier error previo
    setPreFetchLoading(true)
    try {
        setDataSources(() => []);
        const { data, error: apiError } = await apiRequest(
            `/datasources/queries/pre-fetch/`,
            'POST',
            'include',
            'application/json',
            body
        );

        if (apiError) {
            throw new Error(apiError.message);
        }

        if (data.status == "error") {
            throw new Error(data.message);
        }
        setResultsCount(data.data.results_count);
        setSourceHref(data.data.preview_url);

    } catch (error) {
        console.error('Error fetching dataSource:', error);
        setPreFetchError(error.message); // Establece el mensaje de error
    } finally {
      setPreFetchLoading(false); // Termina el estado de carga
    }
  };

  const createDataSourceQueries = async (dataSourceId, collection_name, queryParams) => {
    setError(null); // Limpia cualquier error previo
  
    const body = {data_source: dataSourceId, collection_name: collection_name, query_params: queryParams}
    try {
      const { data, error: apiError } = await apiRequest(
        `/datasources/${dataSourceId}/queries/`,
        'POST',
        'include',
        'application/json',
        body
      );
  
      if (apiError) {
        throw new Error(apiError.message);
      }
      
      // Si la actualización fue exitosa, actualizamos las consultas
      const newData = [...dataSourceQueries, data]; // AÑADIR NUEVA QUERY
      setDataSourceQueries(newData);
  
    } catch (error) {
      console.error('Error updating dataSource query:', error);
      setError(error.message); // Establece el mensaje de error
    } finally {
      setLoading(false); // Termina el estado de carga
    }
  };

  const updateDataSourceQuery = async (dataSourceQueryId, body, dataSourceQueries) => {
    setError(null); // Limpia cualquier error previo
  
    try {
      const { data, error: apiError } = await apiRequest(
        `/datasources/queries/${dataSourceQueryId}/`,
        'PUT',
        'include',
        'application/json',
        body
      );
  
      if (apiError) {
        throw new Error(apiError.message);
      }
      
      // Si la actualización fue exitosa, actualizamos las consultas
      const newData = dataSourceQueries.map((item) =>
        item.id === dataSourceQueryId ? { ...item, active: body.active, estado: !item.estado } : item
      );
  
      // Aquí actualizamos el estado de dataSourceQueries con el nuevo estado
      setDataSourceQueries(newData);
  
    } catch (error) {
      console.error('Error updating dataSource query:', error);
      setError(error.message); // Establece el mensaje de error
    } finally {
      setLoading(false); // Termina el estado de carga
    }
  };

  const deleteDataSourceQueries = async (dataSourceQueryId, dataSourceQueries) => {
    setError(null); // Limpia cualquier error previo
  
    try {
      const { data, error: apiError } = await apiRequest(
        `/datasources/queries/${dataSourceQueryId}/`,
        'DELETE',
        'include',
        'application/json'
      );
  
      if (apiError) {
        throw new Error(apiError.message);
      }
      
      const newData = dataSourceQueries.filter((item) => item.id !== dataSourceQueryId);
      setDataSourceQueries(newData);
  
    } catch (error) {
      console.error('Error updating dataSource query:', error);
      setError(error.message); // Establece el mensaje de error
    } finally {
    }
  };
  

  return {
    dataSources,
    editedDataSourceQuery,
    dataSourceQueries,
    resultsCount,
    sourceHref,
    loading,
    preFetchLoading,
    preFetchError,
    // sendDataSource,
    fetchDataSources,
    fetchDataSourceQuery,
    setDataSourceQueries,
    fetchDataSourceQueries,
    preFetchDataSourceQuery,
    createDataSourceQueries,
    updateDataSourceQuery,
    deleteDataSourceQueries,
  };
};

export default useDataSource;
