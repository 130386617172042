import React, { useEffect, useState } from 'react';
import './DocumentPreview.css'; 
import { usePreviewContext } from '../../../contexts/previewContext';
import getSourceIcon from '../../../services/helpers/getSourceIcon';

const DocumentPreview = ({ doc }) => {
  const { doc_id, metadata, content } = doc;
  const [isExpanded, setIsExpanded] = useState(false); // Estado para controlar la expansión
  const { preview, dispatchPreview } = usePreviewContext();
  const [isSelected, setIsSelected] = useState(false);
  const iconSrc = getSourceIcon(metadata.source);

  useEffect(() => {
    if (doc_id && preview[doc_id]) {
      setIsSelected(preview[doc_id].selected || false);
    }
  }, [preview, doc_id]);

  // Función para manejar el cambio de estado del checkbox
  const handleCheckboxChange = () => {
    const actionType = isSelected ? 'unselect' : 'select';
    dispatchPreview({ type: actionType, selectedDoc: doc });
    setIsSelected(!isSelected); // Cambia el estado local de isSelected
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const formatMetadataValue = (key, value) => {
    if (value) {
      const isUrl = key.toLowerCase().includes("url");
      if (isUrl) {
        const formattedUrl = typeof value === 'string' && !/^https?:\/\//i.test(value)
          ? `https://${value}`
          : value;
        return <a href={formattedUrl} target="_blank" rel="noopener noreferrer">{formattedUrl}</a>;
      }
      return <span>{value}</span>;
    }
  };

  const metadataKeys1 = ["study_id", "url", "status"];
  const metadataKeys2 = ["study_type", "authors", "doi", "pmid", "keywords", "protocol", "sponsor", "indication"];

  const renderMetadataList = (keys) => (
    <div className="metadata">
      {keys.map((key) => (
        metadata[key] && (
          <div key={key} style={{ display: 'flex',}}>
            <strong style={{ marginRight: '5px' }}>
              {key.charAt(0).toUpperCase() + key.slice(1)}:
            </strong>
            {formatMetadataValue(key, metadata[key])}
          </div>
        )
      ))}
    </div>
  );

  return (
    <div className={`document-preview ${isSelected ? 'selected' : ''}`}>
      <div className="document-preview-header">
        <label className="custom-checkbox-container">
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={isSelected}
            onChange={handleCheckboxChange}
          />
          <span className="checkmark"></span>
        </label>
        <div className="document-header-content">
          <img src={iconSrc} alt={metadata.source} className="document-icon" />
          <p className="document-source">{metadata.source}</p>
        </div>
      </div>

      <h2>{metadata.title || metadata.id || doc_id}</h2>

      {renderMetadataList(metadataKeys1)}

      {isExpanded && renderMetadataList(metadataKeys2)}

      <div className="document-preview-row">
        <button className="document-button" onClick={toggleExpand}>
          {isExpanded ? 'Ver menos' : 'Ver contenido'}
        </button>

        <div className={`document-preview-content ${isExpanded ? 'expanded' : 'collapsed'}`}>
          {/* You can place iframe or other content here */}
        </div>
      </div>
    </div>
  );
};

export default DocumentPreview;