import React from 'react';
import { RiCloseFill } from 'react-icons/ri';
import './DeleteAccountModal.css';

const DeleteAccountModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal modal-deleteaccount">
        <div className="modal-header">
          <h2>Eliminar cuenta</h2>
          <button className="close-btn" onClick={onClose}>
            <RiCloseFill size={24} />
          </button>
        </div>
        <div className="modal-body">
          <p>¿Estás seguro de eliminar la cuenta?</p>
          <p>
            <li>La eliminación de tu cuenta es permanente y no se podrá deshacer.</li>
            <li>La eliminación hará que no puedas acceder a los servicios de PomeloChat.</li>
            <li>No puedes crear una cuenta nueva usando la misma dirección de correo electrónico.</li>
            <li>Tus datos se eliminarán en un plazo de 30 días, salvo que conservemos un conjunto limitado de datos durante más tiempo cuando así lo requiera o permita la ley.</li>
            <li>Para obtener más información, lee nuestro <a href="" target="_blank" rel="noopener noreferrer">artículo del centro de ayuda</a>.</li>
          </p>
          <p>Para continuar, haz clic en «Confirmar exportación» a continuación.</p>
        </div>
        <div className="modal-footer">
          <button className="cancel-btn" onClick={onClose}>
            Cancelar
          </button>
          <button className="primary-btn">Eliminar cuenta</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;