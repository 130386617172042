const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer); // Elimina cualquier temporizador existente
    timer = setTimeout(() => {
      func(...args); // Llama a la función después del tiempo definido
    }, delay);
  };
};

export default debounce