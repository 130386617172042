import React, { useState, useEffect, useRef } from 'react';
import './PreviewArea.css';
import { usePreviewContext } from '../../../contexts/previewContext';
import { useDocumentsContext } from '../../../contexts/documentsContext';
import DocumentPreview from '../../previews/DocumentPreview/DocumentPreview';
import ActionHookButton from '../../buttons/ActionHookButton/ActionHookButton';
import { RiCloseFill } from "react-icons/ri";
import { useCurrentMessageContext } from '../../../contexts/currentMessageContext';
import capitalize from '../../../services/helpers/capitalizeText';
import useChat from '../../../hooks/useChat';
import PreviewInput from '../../forms/PreviewInput/PreviewInput';
import { BsThreeDotsVertical } from 'react-icons/bs';

const PreviewArea = () => {
  const { messages, loadingMessage, addMessage } = useChat();
  const { preview, dispatchPreview } = usePreviewContext();
  const { documents } = useDocumentsContext();
  const { currentMessage, dispatchCurrentMessage } = useCurrentMessageContext();
  const [allSelected, setAllSelected] = useState(true);
  const [showPreviewInput, setShowPreviewInput] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMediumScreen, setIsMediumScreen] = useState(false); // Para detectar pantallas <= 900px
  const [dropdownVisible, setDropdownVisible] = useState(false);  // Estado para manejar el dropdown
  const hasItems = Object.keys(preview).length !== 0;
  
  // Referencia al contenedor del dropdown
  const dropdownRef = useRef(null);

  const reducedDocuments = hasItems
    ? currentMessage.documents.reduce((acc, id) => {
        if (documents[id]) acc[id] = { ...documents[id] };
        return acc;
      }, {})
    : {};

  useEffect(() => {
    if (hasItems) {
      const hasAllSelected = Object.values(preview).every(doc => doc.selected === true);
      setAllSelected(hasAllSelected);
    }
  }, [preview, currentMessage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000); // Detecta si es móvil
      setIsMediumScreen(window.innerWidth <= 1244 && window.innerWidth > 767); // Detecta si es pantalla mediana
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Función para cerrar el dropdown si el clic es fuera de él
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    // Añadir el event listener
    document.addEventListener("click", handleClickOutside);

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleCloseClick = () => {
    dispatchCurrentMessage({ type: 'reset' });
    dispatchPreview({ type: 'reset' });
  };

  const handleSelectAll = () => {
    dispatchPreview({ type: 'set', selectedDocument: reducedDocuments });
    dispatchPreview({ type: allSelected ? 'unselect_all' : 'select_all' });
  };

  const handleCreateQueryClick = () => {
    setShowPreviewInput(prev => !prev);
  };

  const toggleDropdown = () => {
    setDropdownVisible(prev => !prev);
  };

  if (!hasItems) return null;

  return (
    <>
      {/* Overlay para el modal en dispositivos móviles */}
      {isMobile && (
        <div
          className="modal-overlay"
          onClick={handleCloseClick}
        ></div>
      )}
      <div className={`preview-area ${isMobile ? 'modal' : ''}`}>
        <div className="preview-header">
          <div className="header-info">
            <p className="source-count">{Object.keys(reducedDocuments).length} fuentes</p>
            <p className="question-title">{capitalize(currentMessage.content)}</p>
          </div>
          <button className="close-btn" onClick={handleCloseClick}>
              <RiCloseFill size={24} />
          </button>
        </div>

        <div className="preview-actions">
          <div className="preview-actions-right">
            <button onClick={handleSelectAll} className="select-all">
              {allSelected ? 'Quitar selección' : 'Seleccionar todo'}
            </button>
            <button className="create-query-btn" onClick={handleCreateQueryClick}>Crear consulta</button>
          </div>
          {isMediumScreen ? (
            // Mostrar icono de tres puntos para pantallas <= 900px
            <div className="dropdown-container" ref={dropdownRef}>
              <button className="three-dots-btn" onClick={toggleDropdown}>
                <BsThreeDotsVertical size={20} />
              </button>
              {dropdownVisible && (
                <div className="dropdown-menu">
                  {[
                    { hook_type: 'ver', label: 'Ver contenido' },
                    { hook_type: 'compartir', label: 'Compartir' },
                    { hook_type: 'añadir_favoritos', label: 'Añadir a favoritos' },
                    { hook_type: 'eliminar', label: 'Eliminar fuentes' },
                  ].map(({ hook_type, label }) => (
                    <div
                      key={hook_type}
                      className="dropdown-item"
                      onClick={() => console.log(`Acción: ${hook_type}`)}
                    >
                      <ActionHookButton hook_type={hook_type} />
                      <span className="dropdown-label">{label}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="actions-sources">
              <ActionHookButton hook_type="ver" />
              <ActionHookButton hook_type="compartir" />
              <ActionHookButton hook_type="añadir_favoritos" />
              <ActionHookButton hook_type="eliminar" />
            </div>
          )}
        </div>

        {showPreviewInput && <PreviewInput onSend={addMessage} loading={loadingMessage} />}

        <div className="document-list">
          {Object.entries(reducedDocuments).map(([doc_id, doc]) => (
            <div key={doc_id} className="document-row">
              <DocumentPreview doc={{ ...doc, doc_id }} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PreviewArea;