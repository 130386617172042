import React, { useState } from 'react';
import './CopyMessageButton.css'; 
import { ReactComponent as IconCopy } from '../../../assets/images/icons/icon-copy.svg';
import ButtonTooltip from '../ButtonTooltip/ButtonTooltip';
const CopyMessageButton = ({ message }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleCopy = () => {
    
    navigator.clipboard.writeText(message)
      .then(() => {
        setTooltipVisible(true);
        setTimeout(() => {
          setTooltipVisible(false);
        }, 1500);
      })
      .catch(err => {
        console.error('Error al copiar el mensaje: ', err);
      });
  };

  return (
    <div className="copy-message-button" onClick={handleCopy} title="Copiar mensaje">
      <IconCopy className="copy-message-icon" />
      <ButtonTooltip message="Respuesta copiada" visible={tooltipVisible} />
    </div>
  );
};

export default CopyMessageButton;
