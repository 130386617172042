import React from 'react';
import { RiCloseFill } from 'react-icons/ri';
import './ManageLinksModal.css';

const ManageLinksModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-managelinks">
        <div className="modal-header">
          <h2>Gestionar enlaces compartidos</h2>
          <button className="close-btn" onClick={onClose}>
            <RiCloseFill size={24} />
          </button>
        </div>
        <div className="modal-body">
          <p>Aquí puedes gestionar tus enlaces compartidos.</p>
        </div>
        <div className="modal-footer">
          <button className="cancel-btn" onClick={onClose}>
            Cancelar
          </button>
          <button className="primary-btn">Guardar</button>
        </div>
      </div>
    </div>
  );
};

export default ManageLinksModal;
