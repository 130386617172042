import React from "react";
import "./SearchBarTable.css";
import { ReactComponent as IconSearch } from "../../../assets/images/icons/icon-search.svg";
import { ReactComponent as IconFilter } from "../../../assets/images/icons/icon-filter.svg";

const SearchBarTable = ({ placeholder = "Buscar fuente...", onFilterClick }) => {
  return (
    <div className="search-bar search-table">
      <div className="icon-left">
        <IconSearch alt="Search Icon" />
      </div>
      <input
        type="text"
        className="search-input"
        placeholder={placeholder}
      />
      <div className="icon-right" onClick={onFilterClick}>
        <IconFilter alt="Search Icon" />
      </div>
    </div>
  );
};

export default SearchBarTable;