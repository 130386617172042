import React, { useState } from "react";
import "./EditableField.css";

const EditableField = ({ label, value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  const handleSave = () => {
    onSave(currentValue);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setCurrentValue(value);
    setIsEditing(false);
  };

  return (
    <div className="field">
        <div className="label-actions">
            <div className="field-label">{label}</div>
            <div className="field-actions">
                {!isEditing && (
                <button onClick={() => setIsEditing(true)} className="edit-btn-account">
                    Editar
                </button>
                )}
                {isEditing && (
                <>
                    <button onClick={handleCancel} className="cancel-btn-account">
                    Cancelar
                    </button>
                    <button onClick={handleSave} className="save-btn-account">
                    Guardar
                    </button>
                </>
                )}
            </div>    
        </div>
        <div className="field-content">
            {isEditing ? (
            <input
                type="text"
                value={currentValue}
                onChange={(e) => setCurrentValue(e.target.value)}
            />
            ) : (
            <span>{value}</span>
            )}
        </div>
    </div>
  );
};

export default EditableField;