import React from 'react';
import './SimpleLoader.css'; // Asegúrate de importar el CSS
import { ThreeDots } from 'react-loader-spinner';

const SimpleLoader = () => {

  return (
    <div>
        <div className="loading-container">
          <ThreeDots visible={true} height="30" width="30" color="#e62e43" radius="9" ariaLabel="loading"/>
          <p className="loading-text">Precargando búsqueda</p>
        </div>
    </div>
  );
};

export default SimpleLoader;
