import React, { useState } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import './ChangePasswordModal.css';

const ChangePasswordModal = ({ onClose }) => {
  // Estados para las contraseñas
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  return (
    <div className="modal-overlay">
      <div className="modal modal-changepassword">
        <div className="modal-header">
          <h2>Actualizar contraseña</h2>
          <button className="close-btn" onClick={onClose}>
            <RiCloseFill size={24} />
          </button>
        </div>
        <div className="modal-body">
          <p>
            Ingresa tu nueva contraseña para actualizarla. Asegúrate de que sea segura y tenga al menos 8 caracteres, incluyendo letras y números.
          </p>
          <div>
            <label htmlFor="current-password">Contraseña actual</label>
            <input
              type="password"
              className="form-log"
              id="current-password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Introduzca su contraseña actual"
            />
          </div>
          <div>
            <label htmlFor="new-password">Contraseña nueva</label>
            <input
              type="password"
              className="form-log"
              id="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Introduzca la contraseña nueva"
            />
          </div>
          <div>
            <label htmlFor="confirm-password">Confirma la nueva contraseña</label>
            <input
              type="password"
              className="form-log"
              id="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Repita la nueva contraseña"
            />
          </div>
        </div>
        <div className="modal-footer">
          <button className="cancel-btn" onClick={onClose}>
            Cancelar
          </button>
          <button 
            className="primary-btn" 
            onClick={() => console.log('Contraseñas actualizadas:', { currentPassword, newPassword, confirmPassword })}
          >
            Actualizar contraseña
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
