import React from "react";
import "./SearchBarExpanded.css";
import { ReactComponent as IconSearch } from "../../../assets/images/icons/icon-search.svg";
import { ReactComponent as IconFilter } from "../../../assets/images/icons/icon-filter.svg";

const SearchBarExpanded = ({
  placeholder = "Buscar fuente...",
  onFilterClick,
  showFilterIcon = true,
}) => {
  return (
    <div className="search-bar search-expanded">
      <div className="icon-left">
        <IconSearch alt="Search Icon" />
      </div>
      <input type="text" className="search-input" placeholder={placeholder} />
      {showFilterIcon && (
        <div className="icon-right" onClick={onFilterClick}>
          <IconFilter alt="Search Icon" />
        </div>
      )}
    </div>
  );
};

export default SearchBarExpanded;