export default function userReducer(user, action) {
    switch (action.type) {
      case "reset": 
        return { id: "", name: "", email: "", preferences: {} };
  
      case "update": {
        const updatedUser = {
          ...user, // Copia del estado actual
          ...action.payload, // Actualiza con las propiedades proporcionadas en `payload`
        };
        return updatedUser; // Devuelve la copia modificada del estado
      }
  
      case "set": {
        return action.user; // Reemplaza completamente el estado actual con el nuevo usuario proporcionado
      }
  
      case "logout": {
        return { id: "", name: "", email: "", preferences: {} }; // Resetea el estado del usuario
      }
  
      default:
        throw new Error("Unhandled action: " + action.type);
    }
  }
  