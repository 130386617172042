import React from 'react';
import './ToggleSidebarButtonFullScreen.css';
import { ReactComponent as IconSidebar } from '../../../assets/images/icons/icon-sidebar.svg';

const ToggleSidebarButtonFullScreen = ({ onClick }) => {
    return (
        <button 
            className="toogle-button-FullScreen"
            onClick={onClick}
        >
            <IconSidebar alt="Sidebar Icon" className="icon" />
        </button>
    );
};

export default ToggleSidebarButtonFullScreen;