import React, { useState } from "react";
import "./EditCargaPeriodica.css";
import NewCargaPeriodicaForm from "../../forms/NewCargaPeriodicaForm/NewCargaPeriodicaForm.jsx";
import iconedit from "../../../assets/images/icons/icon-rename.svg";

const EditCargaPeriodica = ({ id, dataSourceQueries }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="button-with-modal">
      <button
        id="btn-editcargaperiodica"
        onClick={handleOpenModal}
        className="compressed"
      >
        <img src={iconedit} alt="Add icon" />
      </button>
      {showModal && (
        <NewCargaPeriodicaForm show={showModal} onClose={handleCloseModal} existing_id={id} dataSourceQueries={dataSourceQueries}/>
      )}
    </div>
  );
};

export default EditCargaPeriodica;