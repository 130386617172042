import React, { useState } from 'react';
import './FeedbackNoLike.css';
import { RiCloseFill } from "react-icons/ri";


const FeedbackNoLike = ({ onClose }) => {
    // Estado para controlar las opciones seleccionadas
    const [selectedOptions, setSelectedOptions] = useState([]);
    
    // Opciones del feedback
    const options = [
        'Difícil de entender',
        'Incorrecta o engañosa',
        'No responde a mi pregunta',
        'Demasiado breve o incompleta',
        'Demasiado extensa',
        'Desactualizada',
        'Tono inapropiado u ofensivo',
        'Errores ortográficos o gramaticales',
        'Otros',
    ];
    
    // Función para alternar la selección de las opciones
    const toggleOption = (option) => {
        if (selectedOptions.includes(option)) {
        setSelectedOptions(selectedOptions.filter((o) => o !== option)); // Remover opción si ya está seleccionada
        } else {
        setSelectedOptions([...selectedOptions, option]); // Agregar opción si no está seleccionada
        }
    };

  return (
    <div className="modal-overlay">
      <div className="modal-feedback">
        {/* Encabezado */}
        <div className="modal-header">
          <h2>¡Ayúdanos a mejorar!</h2>
          <button className="close-btn" onClick={onClose}>
            <RiCloseFill size={24} />
          </button>
        </div>

        <div className="modal-body">
          <div className="modal-section">
            <p className="modal-question">
              Cuéntanos por qué esta respuesta no cumplió con tus expectativas. Marca todas las opciones que correspondan.
            </p>

            <div className="feedback-options">
              {[
                'Difícil de entender',
                'Incorrecta o engañosa',
                'No responde a mi pregunta',
                'Demasiado breve o incompleta',
                'Demasiado extensa',
                'Desactualizada',
                'Tono inapropiado u ofensivo',
                'Errores ortográficos o gramaticales',
                'Otros',
              ].map((option) => (
                <div
                  key={option}
                  className={`feedback-option ${
                    selectedOptions.includes(option) ? 'selected' : ''
                  }`}
                  onClick={() => toggleOption(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
          <div className="modal-section">
            <p className="freetext">¿Cómo podemos mejorar la respuesta?  <p className="opcional">  (Opcional)</p></p>
            <textarea placeholder="Comenta o añade detalles específicos." />
          </div>
        </div>

        {/* Footer del modal */}
        <div className="modal-footer">
          <button className="cancel-btn" onClick={onClose}>
            Cancelar
          </button>
          <button className="primary-btn">Enviar Feedback</button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackNoLike;
