import React, { useEffect, useState, useRef } from 'react';
import './SidebarConversationButton.css';
import { FaEllipsisV, FaTrash, FaShareAlt } from 'react-icons/fa';
import useChatHistory from '../../../hooks/useChatHistory';
import formatDate from '../../../services/helpers/formatDate';
import { ReactComponent as IconShare} from '../../../assets/images/icons/icon-share.svg';
import { ReactComponent as IconDelete} from '../../../assets/images/icons/icon-delete.svg';
import { ReactComponent as IconFav} from '../../../assets/images/icons/icon-fav.svg';
import { ReactComponent as IconRename} from '../../../assets/images/icons/icon-rename.svg';
import { ReactComponent as IconConsulta } from '../../../assets/images/icons/icon-consulta.svg';
import { ReactComponent as IconLock } from '../../../assets/images/icons/icon-lock.svg';
import { ReactComponent as IconMicroscope } from '../../../assets/images/icons/icon-microscope.svg';
import { ReactComponent as IconNotes } from '../../../assets/images/icons/icon-notes.svg';

const SidebarConversationButton = ({ conversation, active, onClick, className }) => {
  const { deleteConversation } = useChatHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null); // Referencia al contenedor del menú

  const handleDelete = () => {
    deleteConversation(conversation.id);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const getIcon = (chatbot) => {
    switch (chatbot) {
      case "pomeloservices_private":
        return <IconLock className="icon-option" />;
      case "pomeloservices_general":
        return <IconConsulta className="icon-option" />;
      case "pomeloservices_research":
        return <IconMicroscope className="icon-option" />;
      case "pomeloservices_worknotes":
        return <IconNotes className="icon-option" />;
      default:
        return <IconConsulta className="icon-option" />;
    }
  };

  // Cerrar el menú si se hace clic fuera de él
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div className="sidebar-button-wrapper" style={{ position: 'relative' }}>
      <button 
          className={`sidebar-button ${active ? 'active' : ''} ${className || ''}`} 
          onClick={onClick}
      >
          <div className="sidebar-button-content">
              {getIcon(conversation.chatbot_slugname)}
              <span>{conversation.name} - {formatDate(conversation.created)}</span>
          </div>
          <div className="menu-container" ref={menuRef}>
            <FaEllipsisV 
                className="menu-icon" 
                onClick={toggleMenu}
            />
          </div>

      </button>

      {isMenuOpen && ( // Cambia optionsVisible a isMenuOpen
          <div className="options-menu" ref={menuRef}>
              <div className="option">
                  <IconShare className="icon-option" alt="Compartir" />
                  <span>Compartir</span>
              </div>
              <div className="option">
                  <IconRename className="icon-option" alt="Renombrar" />
                  <span>Renombrar</span>
              </div>
              <div className="option">
                  <IconFav className="icon-option" alt="Añadir a favoritos" />
                  <span>Añadir a favoritos</span>
              </div>
              <div className="option" onClick={handleDelete}>
                  <IconDelete className="icon-option" alt="Eliminar" />
                  <span>Eliminar</span>
              </div>
          </div>
      )}
    </div>
  );
};

export default SidebarConversationButton;
