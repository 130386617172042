import React from "react";
import "./AccountArea.css";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import ConfigSection from "./ConfigSection/ConfigSection";

const AccountArea = () => {

  return (
    <div className="account-area">
      <PersonalInfo />
      <ConfigSection />
    </div>
  );
};

export default AccountArea;