import React, { useState } from 'react';
import './ModifyResponseButton.css'; 
import { ReactComponent as IconGenerate } from '../../../assets/images/icons/icon-generate.svg';
import { ReactComponent as IconMasCorto } from '../../../assets/images/icons/icon-mas-corto.svg';
import { ReactComponent as IconMasLargo } from '../../../assets/images/icons/icon-mas-largo.svg';
import { ReactComponent as IconMasCasual } from '../../../assets/images/icons/icon-mas-casual.svg';
import { ReactComponent as IconMasProfesional } from '../../../assets/images/icons/icon-mas-profesional.svg';
import { ReactComponent as IconMasSimple } from '../../../assets/images/icons/icon-mas-simple.svg';

const ModifyResponseButton = () => {
  const [optionsVisible, setOptionsVisible] = useState(false);

  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };

  return (
    <div className="modify-response-button">
      <button onClick={toggleOptions} className="modify-button" aria-label="Generar opciones">
        <IconGenerate className="modify-response-button-icon" />
      </button>

      {optionsVisible && (
        <div className="options-menu">
          <div className="option">
            <IconMasCorto className="icon-option" aria-label="Más corta" />
            <span>Más corta</span>
          </div>
          <div className="option">
            <IconMasLargo className="icon-option" aria-label="Más larga" />
            <span>Más larga</span>
          </div>
          <div className="option">
            <IconMasSimple className="icon-option" aria-label="Más simple" />
            <span>Más simple</span>
          </div>
          <div className="option">
            <IconMasCasual className="icon-option" aria-label="Más casual" />
            <span>Más casual</span>
          </div>
          <div className="option">
            <IconMasProfesional className="icon-option" aria-label="Más profesional" />
            <span>Más profesional</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModifyResponseButton;