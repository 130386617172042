import React, { useState } from "react";
import iconAdd from "../../../assets/images/icons/icon-add.svg";
import "./NewCargaPeriodica.css";
import NewCargaPeriodicaForm from "../../forms/NewCargaPeriodicaForm/NewCargaPeriodicaForm.jsx";

const NewCargaPeriodica = ({ loading, compressed }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="button-with-modal">
      <button
        id="btn-cargaperiodica"
        onClick={handleOpenModal}
        className={compressed ? "compressed" : ""}
      >
        <img src={iconAdd} alt="Add icon" />
        {!compressed && <span>{loading ? "Cargando..." : "Carga periódica"}</span>}
      </button>
      {showModal && (
        <NewCargaPeriodicaForm show={showModal} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default NewCargaPeriodica;