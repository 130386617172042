import React from "react";
import Select from "react-select";
import "./SelectDateSource.css";

const ExpandedSelect = ({ options, selectedValue, onChange, placeholder }) => {
  return (
    <div className="expanded-content-item select">
      <Select
        options={options}
        value={selectedValue}
        onChange={onChange}
        placeholder={placeholder}
        classNamePrefix="custom-select"  // Usamos el prefijo para las clases internas
        styles={{
          control: (base) => ({
            ...base,
            backgroundColor: "var(--bg-dark-expandedtable)",
            borderColor: "var(--gray-500)",
            color: "white",
            borderRadius: "8px",
            height: "48px",
            padding: "0 4px",
            marginTop: "6px",
            width: "220px",  // Establecemos el ancho del control
          }),
          singleValue: (base) => ({
            ...base,
            color: "white",
          }),
          menu: (base) => ({
            ...base,
            backgroundColor: "var(--gray-950)",
            border: "1px solid var(--gray-50)",
            borderRadius: "8px",
            padding: "12px",
            width: "220px",  // Aseguramos que el menú tenga el mismo ancho
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused
              ? "var(--gray-900)"
              : "var(--gray-950)",
            color: "white",
            padding: "10px 12px",
            borderRadius: state.isFocused ? "6px" : "0",
            cursor: "pointer",
          }),
        }}
      />
    </div>
  );
};

export default ExpandedSelect;
