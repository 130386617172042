import { getTodayDate, getWeekNumber, groupMessagesByMonth } from "../services/helpers/sidebar";

/**
 * historyReducer - Un reducer que permite realizar operaciones de escritura sobre el estado de `history` en el `HistoryContext`.
 * 
 * La estructura del estado `history` es un diccionario que categoriza conversaciones 
 * en función de su fecha de creación. Cada clave dentro de `history` representa un período 
 * específico y cada valor es un diccionario que contiene las conversaciones, 
 * donde el ID de cada conversación actúa como la clave.
 * 
 * Estructura de `history`:
 * {
 *   today: { 
 *     [conversationId]: { ...conversationData }
 *   },
 *   thisWeek: { 
 *     [conversationId]: { ...conversationData }
 *   },
 *   lastWeek: { 
 *     [conversationId]: { ...conversationData }
 *   },
 *   thisMonth: { 
 *     [conversationId]: { ...conversationData }
 *   },
 *   lastMonths: { 
 *     [monthYear]: {
 *       [conversationId]: { ...conversationData }
 *     }
 *   }
 * }
 *
 * Acciones manejadas:
 * - "reset": Resetea el estado de `history` a una estructura base vacía.
 * - "set": Establece el estado de `history` basándose en un array de conversaciones. 
 *          Clasifica las conversaciones en los grupos definidos: hoy, esta semana, 
 *          semana pasada, este mes y meses anteriores. Retorna un objeto que 
 *          incluye cada grupo como diccionarios de conversaciones.
 * - "add": Añade una nueva conversación al estado actual de `history`. Si la conversación ya existe, se actualiza su información.
 * - "remove": Elimina una conversación del estado actual de `history`. Si no existe, no se realiza ninguna acción.

 * 
 * @param {Object} history - El estado actual de las conversaciones, que sigue la estructura definida arriba.
 * @param {Object} action - La acción a procesar. Debe contener al menos un tipo.
 * @returns {Object} - El nuevo estado de `history` después de aplicar la acción.
 * 
 * @throws {Error} - Lanza un error si se recibe una acción no manejada.
 */
export default function historyReducer(history, action) {

  switch (action.type) {
    case "reset": 
        return {
          today: {},
          thisWeek: {},
          lastWeek: {},
          thisMonth: {},
          lastMonths: {}
        };

    case "set": {
      const todayMessages = getTodayMessages(action.data);
      const weekMessages = getWeekMessages(action.data);
      const lastWeekMessages = getLastWeekMessages(action.data);
      const monthMessages = getMonthMessages(action.data);
      const yearMessages = getYearMessages(action.data);

      const groupedMessages = {
        today: todayMessages,
        thisWeek: weekMessages,
        lastWeek: lastWeekMessages,
        thisMonth: monthMessages,
        lastMonths: yearMessages
      }

      return groupedMessages;
    }
        
    case "add": {
      const conversation = action.data; // La conversación a añadir

      // Se clona el estado actual para no mutarlo directamente
      const updatedHistory = {
        ...history,
      };

      // Actualizar el estado para el grupo de hoy si corresponde
      const todayMessages = getTodayMessages([conversation]);
      if (Object.keys(todayMessages).length > 0) {
        updatedHistory.today[conversation.id] = conversation;
      }

      // Actualizar el estado para el grupo de esta semana si corresponde
      const weekMessages = getWeekMessages([conversation]);
      if (Object.keys(weekMessages).length > 0) {
        updatedHistory.thisWeek[conversation.id] = conversation;
      }

      // Actualizar el estado para el grupo de la semana pasada si corresponde
      const lastWeekMessages = getLastWeekMessages([conversation]);
      if (Object.keys(lastWeekMessages).length > 0) {
        updatedHistory.lastWeek[conversation.id] = conversation;
      }

      // Actualizar el estado para el grupo de este mes si corresponde
      const monthMessages = getMonthMessages([conversation]);
      if (Object.keys(monthMessages).length > 0) {
        updatedHistory.thisMonth[conversation.id] = conversation;
      }

      // Actualizar el estado para el grupo de meses anteriores si corresponde
      const yearMessages = getYearMessages([conversation]);
      if (yearMessages.length > 0) {
        updatedHistory.lastMonths[yearMessages[0].monthYear] = {
          ...updatedHistory.lastMonths[yearMessages[0].monthYear],
          [conversation.id]: conversation,
        };
      }

      return updatedHistory;
    }

    case "remove": {
      const conversationId = action.id; // ID de la conversación a eliminar

      // Se clona el estado actual para no mutarlo directamente
      const updatedHistory = {
        ...history,
      };

      // Eliminar la conversación de los grupos
      delete updatedHistory.today[conversationId];
      delete updatedHistory.thisWeek[conversationId];
      delete updatedHistory.lastWeek[conversationId];
      delete updatedHistory.thisMonth[conversationId];
      // Eliminar la conversación de los meses anteriores
      for (const monthYear in updatedHistory.lastMonths) {
        if (updatedHistory.lastMonths[monthYear][conversationId]) {
          delete updatedHistory.lastMonths[monthYear][conversationId];
        }
      }

      return updatedHistory;
    }

    default:
    throw new Error("Unhadled action" + action.type);
  }

}

// Función para obtener las conversaciones de hoy
const getTodayMessages = (history) => {
  const today = getTodayDate();
  const todayMessages = history.filter((message) => {
    const createdDate = new Date(message.created);
    return (
      createdDate.getDate() === today.day &&
      createdDate.getMonth() === today.month &&
      createdDate.getFullYear() === today.year
    );
  });

  return todayMessages.reduce((acc, message) => {
    acc[message.id] = message; // Usar el ID como clave
    return acc;
  }, {});
};

// Función para obtener las conversaciones de esta semana
const getWeekMessages = (history) => {
  const today = getTodayDate();
  const weekMessages = history.filter((message) => {
    const createdDate = new Date(message.created);
    return (
      createdDate.getFullYear() === today.year &&
      getWeekNumber(createdDate) === today.week &&
      createdDate.getDate() !== today.day
    );
  });

  return weekMessages.reduce((acc, message) => {
    acc[message.id] = message; // Usar el ID como clave
    return acc;
  }, {});
};

// Función para obtener las conversaciones de la semana pasada
const getLastWeekMessages = (history) => {
  const today = new Date();

  const lastWeekStart = new Date(today);
  lastWeekStart.setDate(today.getDate() - today.getDay() - 7 + (today.getDay() === 0 ? -6 : 1));
  lastWeekStart.setHours(0, 0, 0, 0);

  const lastWeekEnd = new Date(lastWeekStart);
  lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
  lastWeekEnd.setHours(23, 59, 59, 999);

  const lastWeekMessages = history.filter((message) => {
    const createdDate = new Date(message.created);
    return createdDate >= lastWeekStart && createdDate <= lastWeekEnd;
  });

  return lastWeekMessages.reduce((acc, message) => {
    acc[message.id] = message; // Usar el ID como clave
    return acc;
  }, {});
};

// Función para obtener las conversaciones de este mes (excluyendo hoy y esta semana)
const getMonthMessages = (history) => {
  const today = getTodayDate();
  const monthMessages = history.filter((message) => {
    const createdDate = new Date(message.created);
    return (
      createdDate.getFullYear() === today.year &&
      createdDate.getMonth() === today.month &&
      createdDate.getDate() !== today.day &&
      getWeekNumber(createdDate) !== today.week
    );
  });

  return monthMessages.reduce((acc, message) => {
    acc[message.id] = message; // Usar el ID como clave
    return acc;
  }, {});
};

// Función para agrupar las conversaciones por mes del año (anteriores al mes actual)
const getYearMessages = (history) => {
  const groupedMonthMessages = groupMessagesByMonth(
    history.filter((message) => {
      const createdDate = new Date(message.created);
      const today = getTodayDate();
      return (
        createdDate.getFullYear() === today.year &&
        createdDate.getMonth() < today.month
      );
    })
  );

  return Object.keys(groupedMonthMessages).reduce((acc, monthYear) => {
    acc[monthYear] = groupedMonthMessages[monthYear]; // Usar el mes y año como clave
    return acc;
  }, {});
};
