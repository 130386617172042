import React, { useState } from 'react';
import './NoLikeButton.css'; 
import { ReactComponent as Iconnolike } from '../../../assets/images/icons/icon-nolike.svg';
import FeedbackNoLike from '../../forms/FeedbackNoLike/FeedbackNoLike.jsx';

const NoLikeButton = () => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(false); 

  const handleNoLike = () => {
    setShowModal(true);
    setSelected(!selected); 
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <button onClick={handleNoLike} className="nolike-button">
        <Iconnolike className="nolike-button-icon"/>
      </button>
      {showModal && <FeedbackNoLike onClose={closeModal} />}
    </div>
  );
};

export default NoLikeButton;