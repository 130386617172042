import React, { useRef, useEffect, useState } from 'react';
import './BookmarkArea.css';
import MessageBox from '../../messages/MessageBox/MessageBox';
import BasicLoader from '../../loaders/BasicLoader/BasicLoader';

const BookmarkArea = ({ bookmarks, loading }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const bookmarkEndRef = useRef(null);

  // Filtra los bookmarks según el contenido del mensaje
  const filteredBookmarks = bookmarks.filter((bmk) =>
    bmk.message_content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Ordena los bookmarks según la fecha
  const sortedBookmarks = filteredBookmarks.sort((a, b) => {
    const dateA = new Date(a.created);
    const dateB = new Date(b.created);

    if (sortOrder === 'desc') {
      return dateB - dateA;
    } else {
      return dateA - dateB;
    }
  });

  // Función para clasificar los mensajes según la fecha
  const classifyMessages = (bookmarks) => {
    const now = new Date();
    const today = now.setHours(0, 0, 0, 0); // Hoy a medianoche
    const yesterday = today - 24 * 60 * 60 * 1000; // Ayer
    const sevenDaysAgo = today - 7 * 24 * 60 * 60 * 1000; // Hace 7 días
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1).setHours(0, 0, 0, 0); // Primer día del mes actual

    const grouped = {
      today: [],
      yesterday: [],
      last7Days: [],
      thisMonth: [],
      previousMonths: {},
    };

    bookmarks.forEach((bmk) => {
      const messageDate = new Date(bmk.created).setHours(0, 0, 0, 0);

      if (messageDate === today) {
        grouped.today.push(bmk);
      } else if (messageDate === yesterday) {
        grouped.yesterday.push(bmk);
      } else if (messageDate >= sevenDaysAgo && messageDate < today) {
        grouped.last7Days.push(bmk);
      } else if (messageDate >= startOfMonth) {
        grouped.thisMonth.push(bmk);
      } else {
        // Agrupar por mes y año
        const monthYear = `${new Date(bmk.created).getMonth() + 1}-${new Date(bmk.created).getFullYear()}`;
        if (!grouped.previousMonths[monthYear]) {
          grouped.previousMonths[monthYear] = [];
        }
        grouped.previousMonths[monthYear].push(bmk);
      }
    });

    return grouped;
  };

  // Agrupamos los bookmarks por fecha
  const groupedBookmarks = classifyMessages(sortedBookmarks);

  useEffect(() => {
    if (bookmarkEndRef.current) {
      bookmarkEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [groupedBookmarks]);

  return (
    <div className="bookmark-area">
      <div className="bookmark-search">
        <div className="search-bookmarkarea">
          <input
            type="text"
            placeholder="Buscar en bookmarks..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>
        <div className="sort-bar">
          <select
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
            className="sort-select"
          >
            <option value="desc">Más recientes</option>
            <option value="asc">Más antiguos</option>
          </select>
        </div>
      </div>

      {/* Mostrar el loader si está cargando */}
      {loading ? (
        <BasicLoader />
      ) : (
        <>
          {/* Mostrar los bookmarks por categorías */}
          {groupedBookmarks.today.length > 0 && (
            <div className="bookmark-group">
              <h3>Hoy</h3>
              {groupedBookmarks.today.map((bmk, key) => (
                <MessageBox
                  key={key}
                  message_id={bmk.message_id}
                  bookmark_id={bmk.id}
                  conversation_id={bmk.conversation_id}
                  type="bookmark"
                  message={bmk.message_content}
                  created={bmk.created}
                />
              ))}
            </div>
          )}

          {groupedBookmarks.yesterday.length > 0 && (
            <div className="bookmark-group">
              <h3>Ayer</h3>
              {groupedBookmarks.yesterday.map((bmk, key) => (
                <MessageBox
                  key={key}
                  message_id={bmk.message_id}
                  bookmark_id={bmk.id}
                  conversation_id={bmk.conversation_id}
                  type="bookmark"
                  message={bmk.message_content}
                  created={bmk.created}
                />
              ))}
            </div>
          )}

          {groupedBookmarks.last7Days.length > 0 && (
            <div className="bookmark-group">
              <h3>Últimos 7 días</h3>
              {groupedBookmarks.last7Days.map((bmk, key) => (
                <MessageBox
                  key={key}
                  message_id={bmk.message_id}
                  bookmark_id={bmk.id}
                  conversation_id={bmk.conversation_id}
                  type="bookmark"
                  message={bmk.message_content}
                  created={bmk.created}
                />
              ))}
            </div>
          )}

          {groupedBookmarks.thisMonth.length > 0 && (
            <div className="bookmark-group">
              <h3>Este mes</h3>
              {groupedBookmarks.thisMonth.map((bmk, key) => (
                <MessageBox
                  key={key}
                  message_id={bmk.message_id}
                  bookmark_id={bmk.id}
                  conversation_id={bmk.conversation_id}
                  type="bookmark"
                  message={bmk.message_content}
                  created={bmk.created}
                />
              ))}
            </div>
          )}

          {/* Mostrar los bookmarks de meses anteriores, agrupados por mes y año */}
          {Object.keys(groupedBookmarks.previousMonths).length > 0 && (
            <div className="bookmark-group">
              <h3>Meses anteriores</h3>
              {Object.keys(groupedBookmarks.previousMonths).map((monthYear, key) => (
                <div key={key}>
                  <h4>{getMonthName(monthYear)}</h4>
                  {groupedBookmarks.previousMonths[monthYear].map((bmk, idx) => (
                    <MessageBox
                      key={idx}
                      message_id={bmk.message_id}
                      bookmark_id={bmk.id}
                      conversation_id={bmk.conversation_id}
                      type="bookmark"
                      message={bmk.message_content}
                      created={bmk.created}
                    />
                  ))}
                </div>
              ))}
            </div>
          )}

          {/* Mensaje si no hay bookmarks en ningún grupo */}
          {Object.values(groupedBookmarks).every(group => group.length === 0) && (
            <p>No se encontraron bookmarks para mostrar.</p>
          )}

          <div ref={bookmarkEndRef} />
        </>
      )}
    </div>
  );
};

// Función para obtener el nombre del mes y el año
const getMonthName = (monthYear) => {
  const [month, year] = monthYear.split('-');
  const monthNames = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];
  return `${monthNames[parseInt(month) - 1]} ${year}`;
};

export default BookmarkArea;
