import { useState } from 'react';
import apiRequest from '../services/api/apiRequest';
import { useParams } from 'react-router-dom';
import { useDocumentsContext } from '../contexts/documentsContext';
import getErrorMessage from '../services/helpers/getErrorMessage';
import { ConversationProvider, useConversationContext } from '../contexts/conversationContext';
class MessageError extends Error {
  constructor(message, status) {
      super(message); // Call the parent constructor with the message
      this.status = status; // Attach the status property
  }
}

const useChat = () => {
  const [messages, setMessages] = useState([]);
  const [loadingConversation, setLoadingConversation] = useState(false); // Para manejar el estado de carga
  const [loadingMessage, setLoadingMessage] = useState(false); // Para manejar el estado de carga
  const [error, setError] = useState(null); // Para manejar los errores
  const { id } = useParams();
  const { documents, dispatchDocuments } = useDocumentsContext();
  const { conversation, dispatchConversation} = useConversationContext();

  const addMessage = async (message, intention, kwargs) => {
    setError(null); // Limpia cualquier error previo
    setLoadingMessage(true)
    try {
            
      // Agregar mensaje de usuario
      const humanMessage = { msg_id: "temporal", msg_content: message, msg_type: "human" }
      setMessages((prevMessages) => [...prevMessages, humanMessage]);
      dispatchConversation({ type: 'add', message: humanMessage });
      
      const { data, error: apiError, status } = await apiRequest(
          '/chatbot/send_message/',
          'POST',
          'include',
          'application/json',
          { input_message: message, conversation_id: id, intention: intention, kwargs: kwargs }
      );

      // Manejo de error de la API
      if (apiError) {
          message = apiError.message
          throw new MessageError(message, status); // Asegúrate de acceder a data.error, no solo data
      }
      
      // Manejo de error en la respuesta de datos
      if (data.error) {
          message = data.error
          throw new MessageError(message,status); // Asegúrate de acceder a data.error, no solo data
      }

      // Agregar respuesta del chatbot
      const responseMessage = {
          msg_id: data.message_id,
          msg_content: data.response,
          msg_type: "ai",
          msg_metadata: data.metadata
      };
      setMessages((prevMessages) => [...prevMessages, responseMessage]);
      // Actualizar contextos de conversación y documentos
      dispatchDocuments({ type: 'add', responseMessage });
      dispatchConversation({ type: 'add', message: responseMessage });

    } catch (error) {
        console.error('Error adding message:', error);
    
        const errorMessage = {
            msg_id: "",
            msg_content: getErrorMessage(error.status),
            msg_type: "error"
        };
    
        // Add the error message to the state
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
        setError(error.message); // Set the error message
    
        // Set a delay of 5 seconds (5000 milliseconds) before removing the error message
        setTimeout(() => {
            setMessages((prevMessages) => prevMessages.filter(msg => msg.msg_type !== "error")); // Remove the error messages
        }, 5000); // 5000 milliseconds delay
    } finally {
        // Here you can add additional logic that always executes
        setLoadingMessage(false)
    }
  };

  const fetchMessages = async (id) => {
    setLoadingConversation(true); // Inicia el estado de carga
    setError(null); // Limpia cualquier error previo

    try {
        setMessages((prevMessages) => []);
        const { data, error: apiError } = await apiRequest(
            '/conversations/'+id,
            'GET',
            'include',
            'application/json'
        );

        if (apiError) {
            throw new Error(apiError.message);
        }
        const history = data.history
        if (history && Array.isArray(history)) {
          const conversation = {id: id, history: history}
          setMessages(history);
          dispatchConversation({ type: 'set', conversation });
          dispatchDocuments({ type: 'set', history })
        }
        

    } catch (error) {
        console.error('Error fetching messages:', error);
        setError(error.message); // Establece el mensaje de error
    } finally {
      setLoadingConversation(false); // Termina el estado de carga
    }
  };

  return {
    messages,
    loadingConversation,
    loadingMessage,
    addMessage,
    fetchMessages,
  };
};

export default useChat;
