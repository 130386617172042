import React, { useEffect, useState } from 'react';
import './PreviewInput.css';
import { FiSend } from 'react-icons/fi';
import { usePreviewContext } from '../../../contexts/previewContext';
import MessageLoader from '../../loaders/MessageLoader/MessageLoader';

const PreviewInput = ({ onSend, loading }) => {
  const [input, setInput] = useState('');
  const [intention] = useState('doc_question');

  const { preview } = usePreviewContext()

  const [kwargs, setKwargs] = useState({
    doc_ids: Object.keys(preview).filter(key => preview[key].selected),
    metadatas: {},
    related_message: "",
  });
  
  useEffect(() => {
    setKwargs({
      doc_ids: Object.keys(preview).filter(key => preview[key].selected),
      metadatas: {},
      related_message: "test",
    });
  }, [preview]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() && intention.trim()) {
      onSend(input, intention, kwargs);
      setInput(''); // Resetear input
    }
    console.log(preview)
  };
 

  return (
    <div className="chat-input-container">
      <MessageLoader loading={loading}/>
      <form className="chat-input-form" onSubmit={handleSubmit}>
        <div className="input-row">
          <input
            id="input-message"
            type="text"
            className="chat-input"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Escribe tu consulta aquí..."
            disabled={loading}
          />
          <button type="submit" className="chat-send-btn" disabled={loading}>
            <FiSend />
          </button>
        </div>
      </form>
    </div>
  );
};

export default PreviewInput;
