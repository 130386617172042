import React, { useState, useEffect, useRef } from 'react';
import useChatHistory from '../../../hooks/useChatHistory';
import NewQueryButtonFullScreen from '../../buttons/NewQueryButtonFullScreen/NewQueryButtonFullScreen';
import ToggleSidebarButtonFullScreen from '../../buttons/ToggleSidebarButtonFullScreen/ToggleSidebarButtonFullScreen';
import './Header.css';
import ActionHookButton from '../../buttons/ActionHookButton/ActionHookButton';
import { BsThreeDotsVertical } from 'react-icons/bs';

const Header = ({ toggleSidebar, isSidebarVisible, subtitle }) => {
  const { loading } = useChatHistory();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  
  // Referencia al contenedor del dropdown
  const dropdownRef = useRef(null);

  // Detectar tamaño de la pantalla para alternar entre íconos y menú de tres puntos
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Cambiar límite si es necesario
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Función para manejar clic fuera del dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  return (
    <header className="header">
      {isSidebarVisible ? (
        <h1 className="header-title"></h1>
      ) : (
        <div className="sidebar-toggle-container">
          <ToggleSidebarButtonFullScreen
            onClick={toggleSidebar}
            isSidebarVisible={isSidebarVisible}
          />
          <NewQueryButtonFullScreen loading={loading} />
        </div>
      )}
      <div className="action-button-container">
        {isMobile ? (
          <div className="dropdown-container" ref={dropdownRef}>
            <button className="three-dots-btn" onClick={toggleDropdown}>
              <BsThreeDotsVertical size={20} />
            </button>
            {dropdownVisible && (
              <div className="dropdown-menu">
                {[
                  { hook_type: 'print_conversation', label: 'Imprimir' },
                  { hook_type: 'compartir', label: 'Compartir' },
                  { hook_type: 'añadir_favoritos', label: 'Añadir a Favoritos' },
                  { hook_type: 'eliminar', label: 'Eliminar' },
                ].map(({ hook_type, label }) => (
                  <div
                    key={hook_type}
                    className="dropdown-item"
                    onClick={() => console.log(`Acción: ${hook_type}`)}
                  >
                    <ActionHookButton hook_type={hook_type} />
                    <span className="dropdown-label">{label}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <>
            <ActionHookButton hook_type="print_conversation" />
            <ActionHookButton hook_type="compartir" />
            <ActionHookButton hook_type="añadir_favoritos" />
            <ActionHookButton hook_type="eliminar" />
          </>
        )}
      </div>
    </header>
  );
};

export default Header;