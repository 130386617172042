import React from 'react';
import { RiCloseFill } from 'react-icons/ri';
import './ExportDataModal.css';

const ExportDataModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal modal-exportdata">
        <div className="modal-header">
          <h2>¿Estás seguro de solicitar la exportación de datos?</h2>
          <button className="close-btn" onClick={onClose}>
            <RiCloseFill size={24} />
          </button>
        </div>
        <div className="modal-body">
          <p>¿Estás seguro de solicitar la exportación de datos? </p>
          <p>
            <li>Los datos de tu cuenta y tus chats se incluirán en la exportación.</li>
            <li>Los datos se enviarán a tu correo electrónico registrado en un archivo descargable.</li>
            <li>El enlace de descarga caducará 24 horas después de que lo recibas.</li>
            <li>El procesamiento puede tardar un poco. Te enviaremos una notificación cuando esté listo.</li>
          </p>
          <p>Para continuar, haz clic en «Confirmar exportación» a continuación.</p>
        </div>
        <div className="modal-footer">
          <button className="cancel-btn" onClick={onClose}>
            Cancelar
          </button>
          <button className="primary-btn">Confirmar exportación</button>
        </div>
      </div>
    </div>
  );
};

export default ExportDataModal;