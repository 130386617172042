import React from 'react';
import './SidebarFooter.css';
import SidebarButtonFooter from '../../../buttons/SidebarButtonFooter/SidebarButtonFooter';
import useLogout from '../../../../hooks/useLogout';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as IconFav } from '../../../../assets/images/icons/icon-fav.svg';
import { ReactComponent as IconHistorial } from '../../../../assets/images/icons/icon-historial.svg';
import { ReactComponent as IconSettings } from '../../../../assets/images/icons/icon-settings.svg';
import { ReactComponent as IconPermissions } from '../../../../assets/images/icons/icon-permissions.svg';
import { ReactComponent as IconLogout } from '../../../../assets/images/icons/icon-logout.svg';

const SidebarFooter = () => {
    const logout = useLogout();
    const navigate = useNavigate();
    const location = useLocation();

    const routes = [
        // { path: '/chat', icon: IconHistorial, text: 'Historial' },
        { path: '/bookmarks', icon: IconFav, text: 'Favoritos' },
        { path: '/sources', icon: IconPermissions, text: 'Fuentes'},
        { path: '/account', icon: IconSettings, text: 'Mi cuenta' },
        { path: '/logout', icon: IconLogout, text: 'Cerrar sesión', onClick: logout },
    ];

    return (
        <div className="sidebar-footer">
            {routes.map(({ path, icon: Icon, text, onClick }) => (
                <SidebarButtonFooter
                    key={text}
                    text={text}
                    Icon={Icon}
                    onClick={onClick ? onClick : () => navigate(path)}
                    active={location.pathname.startsWith(path)}
                />
            ))}
        </div>
    );
};

export default SidebarFooter;
