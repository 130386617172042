import React, { useState, useEffect, useRef } from "react";
import SearchBarExpanded from "../../../components/SearchBarExpanded/SearchBarExpanded.jsx";
import SelectDateSource from "../../../components/SelectDateSource/SelectDateSource.jsx";
import SelectTypeSource from "../../../components/SelectTypeSource/SelectTypeSource.jsx";
import NewCargaPeriodica from "../../../buttons/NewCargaPeriodica/NewCargaPeriodica.jsx";
import icondelete from "../../../../assets/images/icons/icon-delete.svg";
import iconShare from "../../../../assets/images/icons/icon-share.svg";

import "./ExpandedContent.css";
import useDataSource from "../../../../hooks/useDataSource.js";
import formatDate from "../../../../services/helpers/formatDate.js";
import TableLoader from "../../../loaders/TableLoader/TableLoader"; // Asegúrate de tener el loader importado
import EditCargaPeriodica from "../../../buttons/EditCargaPeriodica/EditCargaPeriodica.jsx";

const ExpandedContent = ({ dataSourceId }) => {
  const { fetchDataSourceQueries, updateDataSourceQuery, deleteDataSourceQueries, dataSourceQueries, loading } = useDataSource();
  const [selectedFecha, setSelectedFecha] = useState(null);
  const [selectedTipoArticulo, setSelectedTipoArticulo] = useState([]);
  const [isCompact, setIsCompact] = useState(false);
  const contentRef = useRef(null);

  const fechaOptions = [
    { value: "hoy", label: "Hoy" },
    { value: "ayer", label: "Ayer" },
    { value: "semana", label: "Última semana" },
  ];

  const tipoArticuloOptions = [
    { value: "clinical_trial", label: "Clinical Trial" },
    { value: "review", label: "Review" },
    { value: "meta_analysis", label: "Meta-Analysis" },
    { value: "books_documents", label: "Books and Documents" },
  ];

  useEffect(() => {
    fetchDataSourceQueries(dataSourceId);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (contentRef.current) {
        setIsCompact(contentRef.current.offsetWidth < 1128);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleToggleEstado = async (id) => {
    const itemToUpdate = dataSourceQueries.find(item => item.id === id);
    if (itemToUpdate) {
      const body = { "active": !itemToUpdate.active };
      await updateDataSourceQuery(id, body, dataSourceQueries);
    }
  };

  const handleDelete = (id) => {
    deleteDataSourceQueries(id, dataSourceQueries);
  };


  const handleVisit = (url) => {
    if (url) {
      window.open(url, "_blank"); // Abre la URL en una nueva pestaña
    } else {
      console.error("URL no válida");
    }
  };

  return (
    <div className="expanded-content" ref={contentRef}>
      <div className="header-table">
        <div className="number-display">
          <div className="number">{dataSourceQueries.length}</div>
          <div className="description">Cargas periódicas</div>
        </div>
        <div className="filters-cta-expanded">
          <SearchBarExpanded
            placeholder="Buscar fuente..."
            onFilterClick={() => console.log("Filtro activado")}
            showFilterIcon={isCompact}
          />
          {!isCompact && (
            <>
              <SelectDateSource
                options={fechaOptions}
                selectedValue={selectedFecha}
                onChange={setSelectedFecha}
                placeholder="Fecha de publicación"
              />
              <SelectTypeSource
                options={tipoArticuloOptions}
                selectedValue={selectedTipoArticulo}
                onChange={setSelectedTipoArticulo}
                placeholder="Tipo de Artículo"
              />
            </>
          )}
          <div className="expanded-content-item cta">
            <NewCargaPeriodica compressed={true} />
          </div>
        </div>
      </div>
      <div className="table">
        <table>
          <thead>
            <tr>
              {[
                "Título",
                "Última actualización",
                "Total resultados",
                "Activo",
                "Acciones",
              ].map((title, index) => (
                <th key={index}>{title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <TableLoader columns={5} />
            ) : (
              dataSourceQueries.map((row) => (
                <tr key={row.id}>
                  <td>{row.query_params.term}</td>
                  <td>{formatDate(row.created)}</td>
                  <td>{row.query_results}</td>
                  <td>
                    <label className="toggle">
                      <input
                        type="checkbox"
                        checked={row.active}
                        onChange={() => handleToggleEstado(row.id)}
                      />
                      <span className="slider"></span>
                    </label>
                  </td>
                  <td className="actions">
                  <EditCargaPeriodica id={row.id}/>
                    <button
                      className="delete-button"
                      onClick={() => handleDelete(row.id)}
                    >
                      <img
                        src={icondelete}
                        alt="Eliminar"
                        className="filter-icon"
                      />
                    </button>
                    <button
                      className="visit-button"
                      onClick={() => handleVisit(row.query_url)}
                    >
                      <img
                        src={iconShare}
                        alt="Eliminar"
                        className="filter-icon"
                      />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExpandedContent;
