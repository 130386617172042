const sourceSchema = [
  {
    id: 1,
    value: "pubmed",
    label: "PubMed Library",
    fields: [
      {name: "term", type: "textarea", label: "Término de búsqueda", value:"", placeholder:"Introduce el término de búsqueda en pubmed", helptext:""},
      {name: "publication_type", type: "select", isMulti:true, label: "Tipo de documento", placeholder:"Selecciona uno o varios", helptext:"Añade filtros para que la búsqueda sea más acotada", options:[
        { value: 'clinical trial', label: 'Ensayo clínico' },
        { value: 'randomized controlled trial', label: 'Ensayo clínico aleatorizado' },
        { value: 'review', label: 'Revisión' },
        { value: 'systematic review', label: 'Revisión sistemática' },
        { value: 'meta-analysis', label: 'Meta-análisis' },
        { value: 'case reports', label: 'Caso de estudio' },
        { value: 'books and documents', label: 'Books and Documents' },
      ]},
      {name: "publication_date", type: "select", label: "Fecha de publicación", placeholder: "selecciona una fecha", helptext: "Selecciona un rango de fechas", options:[
        { value: '1', label: '1 año' },
        { value: '5', label: '5 años' },
        { value: '10', label: '10 años' },
      ]}

    ]
  },
  {
    id: 2,
    value: "reec",
    label: "Ensayos clínicos REEC",
    fields: [
      {name: "title", type: "textarea", label: "Término de búsqueda",  value:"", placeholder:"Introduce el término de búsqueda en reec", helptext:""}
    ]
  },
  ]

export default sourceSchema
