import React from "react";
import "./TableLoader.css";

const TableLoader = ({ columns = 5 }) => {
  return (
    <tr className="table-loader-row">
      {[...Array(columns)].map((_, index) => (
        <td key={index}>
          <div className="table-loader-cell">
            <div className="loader-shimmer"></div>
          </div>
        </td>
      ))}
    </tr>
  );
};

export default TableLoader;
