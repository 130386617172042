import React from 'react';
import { FaBookmark } from 'react-icons/fa';
import './NewBookmarkButton.css';
import useBookmark from '../../../hooks/useBookmark';
import { ReactComponent as IconFav} from '../../../assets/images/icons/icon-fav.svg';

const NewBookmarkButton = ({ active, message_id, message_content, conversation_id, message_type }) => {
    const { bookmarks, loading, addBookmark } = useBookmark();

    const handleIconClick = (event) => {
        console.log(`${message_id}`)
        addBookmark(
            conversation_id=conversation_id,
            message_id=message_id,
            message_type=message_type,
            message_content=message_content
        )
    };

    return (
        <div className={`new-bookmark-button ${active ? 'active' : ''}`} onClick={handleIconClick}>
            <IconFav alt="añadir a favoritos" className="new-bookmark-icon" />
        </div>
    );
};

export default NewBookmarkButton;
