import React, { useState } from 'react';
import './LikeButton.css'; 
import { ReactComponent as Iconlike } from '../../../assets/images/icons/icon-like.svg';

const LikeButton = () => {
  const [liked, setLiked] = useState(false);

  const handleLike = () => {
    setLiked(!liked);
  };

  return (
    <div className="like-button" onClick={handleLike} title="Copiar mensaje">
      <Iconlike className="like-button-icon"/>
    </div>
  );
};

export default LikeButton;