import React, { useState, useEffect, useRef } from "react";
import "./SourcesArea.css";
import { FaChevronDown } from "react-icons/fa";
import BasicLoader from "../../loaders/BasicLoader/BasicLoader";
import SearchBarTable from "../../components/SearchBarTable/SearchBarTable.jsx";
import NewCargaPeriodica from "../../buttons/NewCargaPeriodica/NewCargaPeriodica.jsx";
import iconOrder from "../../../assets/images/icons/icon-filter-order.svg";
import ExpandedContent from "./ExpandedContent/ExpandedContent";
import useDataSource from "../../../hooks/useDataSource.js";
import formatDate from "../../../services/helpers/formatDate.js";

const SourcesArea = () => {
  const { fetchDataSources, dataSources, loading } = useDataSource();
  const [expandedRowId, setExpandedRowId] = useState(null); // Solo una fila expandida
  const [isCompressed, setIsCompressed] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current) {
        setIsCompressed(tableRef.current.offsetWidth < 668);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetchDataSources();
  }, []);

  const toggleRow = (id) => {
    setExpandedRowId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div className="sources-area">
      {loading ? (
        <BasicLoader />
      ) : (
        <div id="main-sources-table" ref={tableRef}>
          <div className="header-table">
            <div className="info-table">
              <div className="title-table">Fuentes de datos</div>
              <div className="total-number-table">
                {dataSources.length} fuentes en total
              </div>
            </div>
            <div className="filters-cta-table">
              <SearchBarTable />
              <NewCargaPeriodica compressed={isCompressed} />
            </div>
          </div>
          <div id="main-sources-data-table" className="table">
            <table>
              <thead>
                <tr>
                  {[
                    "Nombre de la fuente",
                    "Última actualización",
                    "Frec. actualización",
                    "Tipo de conexión",
                    "Total de cargas",
                    "",
                  ].map((title, index) => (
                    <th key={index}>
                      <div className="table-header">
                        {title}
                        {index < 5 && (
                          <img
                            src={iconOrder}
                            alt="Filtro"
                            className="filter-icon"
                          />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataSources.map((row) => {
                  const isExpanded = expandedRowId === row.id;
                  return (
                    <React.Fragment key={row.id}>
                      <tr className={isExpanded ? "expanded-parent" : ""}>
                        <td>{row.name}</td>
                        <td>{formatDate(row.last_update)}</td>
                        <td>{row.update_period}</td>
                        <td>{row.schema}</td>
                        <td>{row.queries_count}</td>
                        <td>
                          <button
                            className="toggle-row-button"
                            onClick={() => toggleRow(row.id)}
                          >
                            <FaChevronDown />
                          </button>
                        </td>
                      </tr>
                      {isExpanded && (
                        <tr className="expanded-row">
                          <td colSpan="6">
                            <div className="expanded-content">
                              <ExpandedContent dataSourceId={row.id} />
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default SourcesArea;
